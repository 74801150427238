import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import admins from "./reducers/admins";
import users from "./reducers/users";
import posts from "./reducers/posts";
import categories from "./reducers/categories";

const rootReducer = combineReducers({
  adminsStore: admins,
  usersStore: users,
  postsStore: posts,
  categoriesStore: categories,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
