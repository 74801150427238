import React, { useState, useRef } from "react";
import style from "./styles.module.scss";
import clsx from "clsx";
import { Arrow } from "../../assets/svg";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import InfiniteScroll from "react-infinite-scroll-component";
import { usePagination } from "../../hooks/usePagination";
const InfiniteSelect = ({
  label,
  error,
  placeholder,
  onChange,
  formik,
  name,
  value = "",
  header = false,
  isDisabled = false,
  url,
  limit,
  id,
  fetchData,
}) => {
  const [data, setData] = useState([]);

  const [isOpen, setOpen] = useState(false);
  const popupRef = useRef();

  useOutsideClick({ ref: popupRef, callback: () => setOpen(false) });

  const { setPage, hasMore } = usePagination({
    setItems: (items, page) =>
      setData((prev) => (page > 1 ? [...prev, ...items] : items)),
    url,
    limit,
    fetchData,
  });

  console.log("DATA", data);
  console.log("hasMore", hasMore);

  let itemsName = data.map((item) => item.name);

  const onSelect = (index) => {
    onChange(data[index], name);
    setOpen(false);
  };

  return (
    <div className={style.selectBlock}>
      <div
        className={clsx(style.selectBlock__label, { [style.focus]: isOpen })}
      >
        {label}
      </div>
      <div
        className={clsx(
          style.select,
          { [style.focus]: isOpen },
          { [style.error]: !!error },
          { [style.adaptive]: header }
        )}
        ref={popupRef}
        onClick={() => {
          !isDisabled && setOpen(!isOpen);
        }}
      >
        {!!placeholder && !formik.values[name] && (
          <div
            className={clsx(style.select__placeholder, {
              [style.error]: !!error,
            })}
          >
            <p>{placeholder}</p>
          </div>
        )}

        <p>
          {formik ? (formik.values[name] ? formik.values[name] : "") : value}
        </p>

        {!isDisabled && (
          <div
            className={clsx(
              style.select__arrow,
              { [style.active]: isOpen },
              { [style.adaptive]: header }
            )}
          >
            <Arrow />
          </div>
        )}

        <div
          className={clsx(style.list__wrapper, { [style.active]: isOpen })}
          onClick={(e) => e.stopPropagation()}
          id={id}
        >
          <div className={clsx(style.list)}>
            <InfiniteScroll
              scrollableTarget={id}
              dataLength={data.length}
              next={() => {
                setPage((prev) => prev + 1);
              }}
              hasMore={hasMore}
              loading={<h4>Loading...</h4>}
              initialScrollY={0}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {!!itemsName &&
                itemsName.map((item, index) => {
                  return (
                    <div
                      key={`item ${index}`}
                      onClick={() => onSelect(index)}
                      className={style.list__item}
                    >
                      {item}
                    </div>
                  );
                })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      {!!error && <div className={style.selectBlock__error}>{error}</div>}
    </div>
  );
};

export default InfiniteSelect;
