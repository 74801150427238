import React from "react";

const pen = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2626 2.17663C17.946 1.49321 19.054 1.49321 19.7375 2.17663L22.3232 4.76241C23.0067 5.44583 23.0067 6.55387 22.3232 7.23729L19.5303 10.0302L19.5179 10.0424L8.68981 20.3783C8.49198 20.5671 8.25264 20.7069 7.99101 20.7866L2.46839 22.4674C2.20341 22.548 1.91555 22.476 1.71969 22.2802C1.52383 22.0843 1.45187 21.7965 1.53251 21.5315L3.20612 16.0325C3.29376 15.7445 3.45421 15.484 3.67196 15.2762L14.4759 4.96335L17.2626 2.17663ZM14.9878 6.54832L4.70768 16.3612C4.67657 16.3909 4.65365 16.4281 4.64113 16.4692L3.37697 20.6229L7.55426 19.3515C7.59164 19.3402 7.62583 19.3202 7.65409 19.2932L17.9269 9.48737L14.9878 6.54832ZM19 8.43919L21.2626 6.17663C21.3602 6.07899 21.3602 5.9207 21.2626 5.82307L18.6768 3.23729C18.5792 3.13965 18.4209 3.13966 18.3232 3.23729L16.0607 5.49985L19 8.43919Z"
        fill="#2B2D33"
      />
    </svg>
  );
};

export default pen;
