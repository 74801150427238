import React, { useState, useEffect } from "react";
import style from "./styles.module.scss";

import CentralBlock from "../../blocks/createPost/centralBlock";
import { useFormik } from "formik";
import { fetchRequest } from "../../API/fetchRequest";
import { fetchFormDataRequest } from "../../API/fetchFormDataRequest";
import { Modal } from "../../components";
import { CreatePostShema } from "../../utils/validations/CreatePostShema";
import { usePagination } from "../../hooks/usePagination";

const CreatePost = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      category: "",
      cover: null,
      audio: null,
      name: "",
      description: "",
      duration: 0,
      author: "",
      authorObj: null,
      categoryObj: null,
    },
    validationSchema: CreatePostShema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      onSubmit();
    },
  });

  const onChange = (value, name) => {
    if (name === "author") {
      formik.setFieldValue("author", value.name);
      formik.setFieldValue("authorObj", value);

      return;
    }

    if (name === "category") {
      formik.setFieldValue("category", value.name);
      formik.setFieldValue("categoryObj", value);
      return;
    }
    formik.setFieldValue(name, value);
  };

  const onSubmit = async () => {
    setShowModal(true);
    const values = formik.values;
    const formData = new FormData();
    Object.keys(values).forEach((item) => {
      if (item === "category") {
        formData.append(item, values.categoryObj._id);
      } else if (item === "author") {
        formData.append(item, values.authorObj._id);
      } else {
        formData.append(item, values[item]);
      }
    });

    const resp = await fetchFormDataRequest(
      "POST",
      "admin/post",
      formData,
      true
    );
    console.log("resp", resp);
    setShowModal(false);
    if (resp && resp.success) {
      formik.resetForm();
      setShowSuccessModal(true);
    }
  };

  return (
    <div className={style.createPost}>
      {showModal && (
        <Modal
          text="Are you sure you want to save this post?"
          showLoader={true}
          onClose={() => setShowModal(false)}
          onSubmit={onSubmit}
        />
      )}
      {showSuccessModal && (
        <Modal
          title="Post successfully created!"
          onClose={() => setShowSuccessModal(false)}
          onSubmit={() => setShowSuccessModal(false)}
          showSuccessButton={true}
        />
      )}
      <CentralBlock formik={formik} onChange={onChange} />
    </div>
  );
};

export default CreatePost;
