import * as Yup from "yup";
export const FILE_SIZE = 1024 * 1024;
export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
export const EditCategoryShema = Yup.object().shape({
  name: Yup.string()
    .required("Name for category is a required field")
    .required("Name is requiered"),
  cover: Yup.mixed()
    .test("fileSize", "File too large", (value) =>
      value ? value.size <= FILE_SIZE * 10 : true
    )
    .test("fileFormat", "Unsupported Format", (value) =>
      value ? SUPPORTED_FORMATS.includes(value.type) : true
    )
    .nullable(),
});
