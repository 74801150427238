import React from "react";

const userManagement = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8867 12.1463C17.5187 12.1463 17.1977 11.8753 17.1447 11.5003C17.0877 11.0893 17.3727 10.7103 17.7837 10.6533C18.8237 10.5073 19.6087 9.60526 19.6107 8.55326C19.6107 7.51026 18.8627 6.62826 17.8317 6.45926C17.4237 6.39226 17.1467 6.00626 17.2137 5.59726C17.2817 5.18826 17.6647 4.91426 18.0757 4.97926C19.8337 5.26826 21.1107 6.77226 21.1107 8.55526C21.1067 10.3503 19.7657 11.8903 17.9907 12.1393C17.9557 12.1443 17.9207 12.1463 17.8867 12.1463Z"
        fill="#808185"
      />

      <g mask="url(#mask0_1003_1544)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.8613 18.531C20.5593 18.531 20.2743 18.347 20.1603 18.048C20.0123 17.661 20.2073 17.227 20.5943 17.08C21.2723 16.822 21.2723 16.537 21.2723 16.4C21.2723 15.962 20.7153 15.656 19.6173 15.492C19.2083 15.43 18.9253 15.049 18.9863 14.638C19.0473 14.228 19.4273 13.955 19.8403 14.008C22.2633 14.371 22.7723 15.509 22.7723 16.4C22.7723 16.944 22.5583 17.937 21.1283 18.482C21.0403 18.515 20.9503 18.531 20.8613 18.531Z"
          fill="#808185"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8865 15.9141C9.51049 15.9141 6.67749 16.2061 6.67749 17.5961C6.67749 18.9971 9.51049 19.2911 11.8865 19.2911C14.2625 19.2911 17.0945 19.0001 17.0945 17.6131C17.0945 16.2091 14.2625 15.9141 11.8865 15.9141ZM11.8865 20.7911C10.2275 20.7911 5.17749 20.7911 5.17749 17.5961C5.17749 14.4141 10.2275 14.4141 11.8865 14.4141C13.5455 14.4141 18.5945 14.4141 18.5945 17.6131C18.5945 20.7911 13.7215 20.7911 11.8865 20.7911Z"
        fill="#808185"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8866 5.5C10.1946 5.5 8.81864 6.877 8.81864 8.569C8.81564 9.389 9.12964 10.156 9.70464 10.735C10.2796 11.314 11.0456 11.635 11.8616 11.638L11.8866 12.388V11.638C13.5786 11.638 14.9556 10.262 14.9556 8.569C14.9556 6.877 13.5786 5.5 11.8866 5.5ZM11.8866 13.138H11.8596C10.6396 13.134 9.49664 12.656 8.64064 11.792C7.78264 10.929 7.31364 9.783 7.31864 8.566C7.31864 6.05 9.36764 4 11.8866 4C14.4066 4 16.4556 6.05 16.4556 8.569C16.4556 11.088 14.4066 13.138 11.8866 13.138Z"
        fill="#808185"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.8856 12.1465C5.8516 12.1465 5.8166 12.1445 5.7816 12.1395C4.0066 11.8905 2.6666 10.3505 2.6626 8.55745C2.6626 6.77245 3.9396 5.26845 5.6976 4.97945C6.1186 4.91345 6.4916 5.19045 6.5596 5.59745C6.6266 6.00645 6.3496 6.39245 5.9416 6.45945C4.9106 6.62845 4.1626 7.51045 4.1626 8.55545C4.1646 9.60545 4.9496 10.5085 5.9886 10.6535C6.3996 10.7105 6.6846 11.0894 6.6276 11.5005C6.5746 11.8755 6.2536 12.1465 5.8856 12.1465Z"
        fill="#808185"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.912 18.5313C2.823 18.5313 2.733 18.5153 2.645 18.4823C1.214 17.9363 1 16.9433 1 16.4003C1 15.5103 1.509 14.3713 3.933 14.0083C4.346 13.9563 4.724 14.2283 4.786 14.6383C4.847 15.0493 4.564 15.4303 4.155 15.4923C3.057 15.6563 2.5 15.9623 2.5 16.4003C2.5 16.5373 2.5 16.8213 3.179 17.0803C3.566 17.2273 3.761 17.6613 3.613 18.0483C3.499 18.3473 3.214 18.5313 2.912 18.5313Z"
        fill="#808185"
      />
    </svg>
  );
};

export default userManagement;
