import React from "react";
import style from "./styles.module.scss";
import { Input, Button } from "../../../components";
import { fetchRequest } from "../../../API/fetchRequest";
import { useFormik } from "formik";
import { CreateAdminShema } from "../../../utils/validations/CreateAdminShema";
import { useDispatch } from "react-redux";
import { getAdmins } from "../../../redux/actions/admins";

const CentralBlock = () => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: CreateAdminShema,
    onSubmit: async (values) => {
      const resp = await fetchRequest(
        "POST",
        "admin/add",
        { login: values.email, password: values.password },
        true
      );
      console.log("resp", resp);

      if (resp.success) {
        formik.resetForm();
        dispatch(getAdmins());
      }
    },
  });

  const onChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const onSubmit = () => {
    formik.submitForm();
  };

  return (
    <div className={style.centralBlock}>
      <div className={style.centralBlock__title}>Create new admin</div>
      <Input
        label="Email"
        formik={formik}
        name="email"
        placeholder={"Email"}
        onChange={onChange}
        error={formik.touched.email && formik.errors.email}
      />
      <div className={style.centralBlock__field}>
        <Input
          formik={formik}
          placeholder={"Password"}
          name="password"
          onChange={onChange}
          label="Password"
          error={formik.touched.password && formik.errors.password}
        />
      </div>
      <div className={style.centralBlock__field}>
        <Input
          formik={formik}
          placeholder={"Repeat password"}
          name="passwordConfirmation"
          onChange={onChange}
          label="Repeat password"
          error={
            formik.touched.passwordConfirmation &&
            formik.errors.passwordConfirmation
          }
        />
      </div>

      <div className={style.centralBlock__button}>
        <Button text="Confirm" onClick={onSubmit} />
      </div>
    </div>
  );
};

export default CentralBlock;
