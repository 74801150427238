import React, { useEffect, useState } from "react";
import {
  DropZone,
  Button,
  Input,
  TextArea,
  Select,
  Modal,
  InfiniteSelect,
} from "..";
import { fetchFormDataRequest } from "../../API/fetchFormDataRequest";
import style from "./styles.module.scss";
import { EditPostShems } from "../../utils/validations/EditPostShema";
import { SUPPORTED_COVER_FORMATS } from "../../constants/supportedCoverFormats";
import { useFormik } from "formik";
import { fetchRequest } from "../../API/fetchRequest";
const PostItemEdit = ({ item, updateTable }) => {
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const SUPPORTED_AUDIO_FORMAT = "audio/mpeg";

  const formik = useFormik({
    initialValues: {
      cover: null,
      name: "",
      description: "",
      category: "",
      audio: null,
      categoryObj: null,
      author: "",
      authorObj: null,
      authorId: "",
      categoryId: "",
    },
    validationSchema: EditPostShems,
    onSubmit: async (values, { resetForm }) => {
      // setShowModal(true);
      onSubmit();
    },
  });

  const onChange = (value, name) => {
    // alert(1)
    if (name === "category") {
      formik.setFieldValue(name, value.name);
      formik.setFieldValue("categoryObj", value);

      return;
    }
    if (name === "author") {
      formik.setFieldValue(name, value.name);
      formik.setFieldValue("authorObj", value);

      return;
    }
    formik.setFieldValue(name, value);
  };

  const categoriesNames = categories.map((item) => item.name);

  const saveCover = (file) => {
    if (SUPPORTED_COVER_FORMATS.find((item) => item === file[0].type)) {
      onChange(file[0], "cover");
    }
  };
  const saveMP3 = (file) => {
    if (!file && typeof file === "object") {
      onChange(null, "audio");

      return;
    }
    if (file[0].type === SUPPORTED_AUDIO_FORMAT) {
      onChange(file[0], "audio");
    }
  };

  const onSubmit = async () => {
    setShowModal(true);

    const values = formik.values;
    const formData = new FormData();

    console.log("VALUES", values);
    Object.keys(values).forEach((item) => {
      if (item === "category") {
        if (values.categoryObj) {
          formData.append(item, values.categoryObj._id);
        } else {
          formData.append(item, values.categoryId);
        }
      } else if (item === "author") {
        console.log("================= 1");
        if (values.authorObj) {
          console.log("================= 2");

          formData.append(item, values.authorObj._id);
        } else {
          console.log("================= 3");

          formData.append(item, values.authorId);
        }
      } else {
        formData.append(item, values[item]);
      }
    });

    formData.append("id", item._id);

    const resp = await fetchFormDataRequest(
      "PUT",
      "admin/post",
      formData,
      true
    );
    setShowModal(false);
    if (resp && resp.success) {
      updateTable && updateTable();
      formik.resetForm();
    }
  };

  const getCategories = async (searchQuery) => {
    const resp = await fetchRequest(
      "POST",
      "admin/categories",
      { default: true },
      true
    );

    if (resp.success) {
      setCategories(resp.categories);
    }
  };

  useEffect(() => {
    console.log("ITEM", item);
    formik.setFieldValue("name", item.name);
    formik.setFieldValue("description", item.description);
    formik.setFieldValue("categoryId", item.category ? item.category : "");
    formik.setFieldValue(
      "category",
      item.categoryName ? item.categoryName : ""
    );
    formik.setFieldValue("author", item.authorName ? item.authorName : "");
    formik.setFieldValue("authorId", item.author ? item.author : "");

    getCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={style.itemEdit}>
      {showModal && <Modal showLoader={true} />}
      <div className={style.itemEdit__left}>
        <div className={style.itemEdit__leftDropZone}>
          <DropZone
            file={formik.values.cover}
            setFile={saveCover}
            removeFile={() => onChange(null, "cover")}
            label="Post cover"
          />
        </div>
        <DropZone
          isMP3={true}
          label={"Audio file"}
          setFile={saveMP3}
          file={formik.values.audio}
          formik={formik}
        />
      </div>

      <div className={style.itemEdit__central}>
        <InfiniteSelect
          label="Category name"
          placeholder="Choose a category"
          formik={formik}
          name="category"
          url="admin/categories"
          limit={15}
          onChange={onChange}
          error={formik.touched.category && formik.errors.category}
          id={`postEditCateg${item._id}`}
          fetchData={{ default: true }}
        />
        <div className={style.itemEdit__centralItem}>
          <InfiniteSelect
            label="Author name"
            placeholder="Choose author"
            formik={formik}
            name="author"
            url="admin/authors"
            limit={15}
            onChange={onChange}
            error={formik.touched.author && formik.errors.author}
            id={`postEditAuth${item._id}`}
          />
        </div>
        <div className={style.itemEdit__centralItem}>
          <Input
            label={"Post title"}
            placeholder={"Text"}
            formik={formik}
            name={"name"}
            onChange={onChange}
            error={formik.touched.name && formik.errors.name}
          />
        </div>
        <div className={style.itemEdit__centralItem}>
          <TextArea
            label={"Post description"}
            formik={formik}
            name={"description"}
            placeholder={"Text"}
            onChange={onChange}
          />
        </div>
        <div className={style.itemEdit__button}>
          <Button text="Save changes" onClick={() => formik.submitForm()} />
        </div>
      </div>
    </div>
  );
};

export default PostItemEdit;
