import React, { useState } from "react";
import style from "./styles.module.scss";
import TableBody from "../../common/tableBody";
import { fetchRequest } from "../../../API/fetchRequest";
import { Modal, Select } from "../../../components";

const tableColumn = [
  { title: "Post date", type: "date", width: "12.5%" },
  { title: " ", type: "image", width: "14.9%" },
  { title: "Post title", type: "name", width: "24.6%" },
  { title: "Number of posts", type: "count", width: "26.3%" },
  { title: " ", type: "editOrDelete", width: "18.3%" },
];

const CentralBlock = ({
  data,
  loading,
  updateData,
  setPage,
  hasMore,
  isAuthor,
  contentType,
  setContentType,
  contentTypeNames,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const item = selectedItem
    ? data.find((item) => item._id === selectedItem)
    : null;
  console.log(item);

  const removeCategory = async () => {
    const resp = await fetchRequest(
      "delete",
      isAuthor ? "admin/author" : "admin/category",
      { ID: selectedItem },
      true
    );
    setShowModal(false);
    if (resp.success) {
      updateData();
    }
  };

  return (
    <div className={style.centralBlock}>
      <div className={style.centralBlock__header}>
        <div className={style.centralBlock__title}>Categories & Authors</div>
        <div className={style.centralBlock__headerSelect}>
          <Select
            value={contentType}
            data={contentTypeNames}
            onChange={(val) => setContentType(val)}
          />
        </div>
      </div>

      <TableBody
        loading={loading}
        isCategoryTable={true}
        tableColumn={tableColumn}
        data={data}
        removeItem={(id) => {
          setSelectedItem(id);
          setShowModal(true);
        }}
        updateTable={updateData}
        setPage={setPage}
        hasMore={hasMore}
      />
      {showModal && (
        <Modal
          title={"Delete Category?"}
          subtitle={
            isAuthor
              ? `Are you sure you want to delete this author?`
              : `Are you sure you want to delete this category? By deleting it, you will also delete the ${item.count} posts irretrievably that are in it.`
          }
          onClose={() => {
            setShowModal(false);
            setSelectedItem(null);
          }}
          onSubmit={removeCategory}
        />
      )}
    </div>
  );
};

export default CentralBlock;
