import React, { useEffect, useState } from "react";
import { DropZone, Button, Input, TextArea, Select, Checkbox } from "..";
import { fetchFormDataRequest } from "../../API/fetchFormDataRequest";
import style from "./styles.module.scss";
import { EditCategoryShema } from "../../utils/validations/EditCategoryShema";
import { useFormik } from "formik";
import { SUPPORTED_COVER_FORMATS } from "../../constants/supportedCoverFormats";
import { totalCategories } from "../../constants/selectArrays";

const CategoryItemEdit = ({ item, updateTable }) => {
  const [isAuthor, setIsAuthor] = useState(false);
  const formik = useFormik({
    initialValues: {
      cover: null,
      name: "",
      description: "",
      mainCateg: "",
    },
    validationSchema: EditCategoryShema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);

      if (item?.mainCateg) {
        updateCategory();
      } else {
        updateAuthor();
      }
    },
  });

  const saveCover = (file) => {
    if (SUPPORTED_COVER_FORMATS.find((item) => item === file[0].type)) {
      onChange(file[0], "cover");
    }
  };

  const onChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const updateAuthor = async () => {
    const values = formik.values;
    const formData = new FormData();
    Object.keys(values).forEach((item) => {
      if (item !== "mainCateg") {
        if (item === "cover") {
          formData.append("avatar", values[item]);
        } else {
          formData.append(item, values[item]);
        }
      }
    });

    formData.append("id", item._id);

    const resp = await fetchFormDataRequest(
      "PUT",
      "admin/author",
      formData,
      true
    );
    console.log("resp", resp);
    if (resp && resp.success) {
      updateTable && updateTable();
      formik.resetForm();
    }
  };

  const updateCategory = async () => {
    const values = formik.values;
    const formData = new FormData();
    Object.keys(values).forEach((item) => {
      formData.append(item, values[item]);
    });

    formData.append("id", item._id);

    const resp = await fetchFormDataRequest(
      "PUT",
      "admin/category",
      formData,
      true
    );
    console.log("resp", resp);
    if (resp && resp.success) {
      updateTable && updateTable();
      formik.resetForm();
    }
  };

  const setDefaultData = () => {
    console.log("setDefaultData --> item", item);
    formik.setFieldValue("name", item.name);
    formik.setFieldValue("description", item.description);
    formik.setFieldValue("mainCateg", item.mainCateg);
    setIsAuthor(!item.mainCateg);
  };

  useEffect(() => {
    setDefaultData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={style.itemEdit}>
      <div className={style.itemEdit__left}>
        <div className={style.itemEdit__leftDropZone}>
          <DropZone
            label={isAuthor ? "Author photo" : "Category cover"}
            file={formik.values.cover}
            setFile={saveCover}
            removeFile={() => onChange(null, "cover")}
          />
        </div>
      </div>
      <div className={style.itemEdit__right}>
        <Input
          label={isAuthor ? "Author's name" : "Category name"}
          formik={formik}
          onChange={onChange}
          name="name"
          error={formik.touched.name && formik.errors.name}
        />

        {item.mainCateg && (
          <div className={style.itemEdit__textarea}>
            <Select
              data={totalCategories}
              label={"Total category"}
              name="mainCateg"
              formik={formik}
              onChange={onChange}
            />
          </div>
        )}

        <div className={style.itemEdit__textarea}>
          <TextArea
            formik={formik}
            onChange={onChange}
            name="description"
            label={isAuthor ? "Author's description" : "Category description"}
            placeholder={"Text"}
          />
        </div>

        <div className={style.itemEdit__button}>
          <Button text="Save changes" onClick={() => formik.submitForm()} />
        </div>
      </div>
    </div>
  );
};

export default CategoryItemEdit;
