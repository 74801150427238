import React, { useState } from "react";
import style from "./styles.module.scss";
import { defaultCategoriesForPost } from "../../../constants/selectArrays";
import {
  Select,
  DropZone,
  Input,
  TextArea,
  Button,
  InfiniteSelect,
} from "../../../components";
import { SUPPORTED_COVER_FORMATS } from "../../../constants/supportedCoverFormats";
const SUPPORTED_AUDIO_FORMAT = "audio/mpeg";

const CentralBlock = ({ formik, onChange }) => {
  const saveCover = (file) => {
    if (SUPPORTED_COVER_FORMATS.find((item) => item === file[0].type)) {
      onChange(file[0], "cover");
    }
  };

  const saveMP3 = (file) => {
    if (!file && typeof file === "object") {
      onChange(null, "audio");

      return;
    }
    if (file[0].type === SUPPORTED_AUDIO_FORMAT) {
      onChange(file[0], "audio");
    }
  };

  return (
    <div className={style.centralBlock}>
      <div className={style.centralBlock__title}>Create Post</div>
      <div className={style.centralBlock__wrapper}>
        <div className={style.centralBlock__left}>
          <DropZone
            label={"Post cover"}
            setFile={saveCover}
            file={formik.values.cover}
            removeFile={() => onChange(null, "cover")}
            error={formik.touched.cover && formik.errors.cover}
          />

          <div className={style.centralBlock__leftMP3Dropzone}>
            <DropZone
              label={"Audio file"}
              isMP3={true}
              file={formik.values.audio}
              formik={formik}
              setFile={saveMP3}
              error={formik.touched.audio && formik.errors.audio}
            />
          </div>
        </div>

        <div className={style.centralBlock__right}>
          <InfiniteSelect
            label="Category name"
            placeholder="Choose a category"
            formik={formik}
            name="category"
            url="admin/categories"
            limit={15}
            onChange={onChange}
            error={formik.touched.category && formik.errors.category}
            id={"id1"}
            fetchData={{ default: true }}
          />
          <div className={style.centralBlock__rightItem}>
            <Input
              label={"Post title"}
              placeholder={"Text"}
              name={"name"}
              formik={formik}
              onChange={onChange}
              error={formik.touched.name && formik.errors.name}
            />
          </div>
          <div className={style.centralBlock__rightItem}>
            <InfiniteSelect
              label="Author name"
              placeholder="Choose author"
              formik={formik}
              name="author"
              url="admin/authors"
              limit={15}
              onChange={onChange}
              error={formik.touched.author && formik.errors.author}
              id={"id2"}
            />
          </div>
          <div className={style.centralBlock__rightItem}>
            <TextArea
              label={"Post description"}
              name={"description"}
              formik={formik}
              onChange={onChange}
              placeholder={"Text"}
            />
          </div>

          <div className={style.centralBlock__rightBtnWrapper}>
            <div className={style.centralBlock__rightBtn}>
              <Button
                text={"Save and publish"}
                onClick={() => formik.submitForm()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CentralBlock;
