import {
  SET_ADMINS,
  CLEAR_ADMINS,
  SET_CURRENT_ADMIN,
  CLEAR_CURRENT_ADMIN,
} from "../actionTypes/admins";

const defaultState = {
  admins: [],
  currentAdmin: null,
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ADMINS: {
      return {
        ...state,
        admins: [...action.payload],
      };
    }
    case CLEAR_ADMINS: {
      return { ...state, admins: [] };
    }
    case SET_CURRENT_ADMIN: {
      return {
        ...state,
        currentAdmin: { ...action.payload },
      };
    }
    case CLEAR_CURRENT_ADMIN: {
      return { ...state, currentAdmin: null };
    }
    default:
      return state;
  }
};
export default user;
