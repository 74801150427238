import React, { useState } from "react";
import style from "./styles.module.scss";
import { icons } from "../../assets/png";
import { Pen, Box } from "../../assets/svg";
import clsx from "clsx";
import moment from "moment";
import { API } from "../../constants/api";
import { Arrow } from "../../assets/svg";
import TableItemEdit from "../CategoryItemEdit";
import PostItemEdit from "../PostItemEdit";
const TableItem = ({
  updateTable,
  tableColumn,
  isUserTable,
  isCategoryTable,
  item,
  removeItem,
}) => {
  let date = item.createdAt
    ? moment(item.createdAt).format("DD/MM/YYYY")
    : moment().format("DD/MM/YYYY");

  const [showEdit, setShowEdit] = useState(false);

  return (
    <div>
      <div
        className={clsx(style.tableItem, { [style.underline]: isUserTable })}
      >
        {tableColumn.map((row) => {
          if (row.type === "editOrDelete") {
            return (
              <div
                key={row.type}
                className={style.tableItem__editBlock}
                style={{ width: row.width }}
              >
                <div
                  className={style.tableItem__pen}
                  onClick={() => setShowEdit(!showEdit)}
                >
                  <Pen />
                </div>
                <div
                  className={style.tableItem__box}
                  onClick={() => {
                    removeItem(item._id);
                  }}
                >
                  <Box />
                </div>
              </div>
            );
          } else if (row.type === "image") {
            return (
              <div
                key={row.type}
                className={style.tableItem__test}
                style={{ width: row.width }}
              >
                {console.log("cover", `${API}/files/${item.cover}`)}
                {item.cover || item.avatar ? (
                  <img
                    src={`https://vipassana.s3.amazonaws.com/uploads/${
                      item.cover ? item.cover : item.avatar
                    }`}
                    className={style.tableItem__cover}
                    alt="Default icon"
                  />
                ) : (
                  <img src={icons.defaultItemIcon} alt="Default icon" />
                )}
              </div>
            );
          } else if (row.type === "date") {
            return (
              <div
                key={row.type}
                style={{ width: row.width }}
                className={clsx(style.tableItem__date, style.tableItem__row)}
              >
                {date}
              </div>
            );
          } else if (row.type === "userName") {
            return (
              <div
                key={row.type}
                style={{ width: row.width }}
                className={clsx(style.tableItem__date, style.tableItem__row)}
              >
                {item.firstName} {item.lastName}
              </div>
            );
          } else if (row.type === "email") {
            return (
              <div
                key={row.type}
                style={{ width: row.width }}
                className={clsx(style.tableItem__date, style.tableItem__row)}
              >
                {item.phone}
                {item.phone && item.email && " / "}
                {item.email}
              </div>
            );
          } else if (row.type === "name" && !isUserTable) {
            return (
              <div key={row.type} style={{ width: row.width }}>
                <div className={style.tableItem__title}>{item[row.type]}</div>

                <div
                  className={style.tableItem__openText}
                  onClick={() => setShowEdit(!showEdit)}
                >
                  View all information <Arrow />
                </div>
              </div>
            );
          } else if (row.type === "categoryName") {
            return (
              <div key={row.type} style={{ width: row.width }}>
                {item[row.type]}
              </div>
            );
          } else {
            return (
              <div key={row.type} style={{ width: row.width }}>
                {item[row.type]}
              </div>
            );
          }
        })}
      </div>
      {showEdit &&
        (isCategoryTable ? (
          <TableItemEdit updateTable={updateTable} item={item} />
        ) : (
          <PostItemEdit updateTable={updateTable} item={item} />
        ))}
    </div>
  );
};

export default TableItem;
