const regexp = /^\d{0,2}?:?\d{0,2}$/u;

const isValidMinute = (minute, hours) => {
  return Number.isInteger(minute) && minute >= 0 && minute < 60;
};

const isValidHour = (hour) => {
  return Number.isInteger(hour) && hour >= 0 && hour <= 12;
};

const isValid = (value) => {
  if (!regexp.test(value)) {
    return false;
  }

  const parts = value.split(":");
  const hourString = parts[0] || "";
  const minuteString = parts[1] || "";
  const hours = parseInt(hourString) || 0;
  const minutes = parseInt(minuteString) || 0;

  if (!isValidHour(hours) || !isValidMinute(minutes, hours)) {
    return false;
  }

  if (minutes < 10 && Number(minuteString[0]) > 5) {
    return false;
  }

  return true;
};

export const getNewValue = (prevValue, newValue) => {
  if (newValue === prevValue || !isValid(newValue)) {
    return { isCorrect: false, val: prevValue };
  }

  const parts = newValue.split(":");
  const hourString = parts[0] || "";
  const hours = parseInt(hourString) || 0;

  if (newValue === "12:0") {
    return { isCorrect: true, val: "1" };
  }
  if (hours === 12) {
    return { isCorrect: true, val: "12:00" };
  }

  if (
    newValue.length === 2 &&
    prevValue.length !== 3 &&
    !newValue.includes(":")
  ) {
    newValue = `${newValue}:`;
  }

  if (newValue.length === 2 && prevValue.length === 3) {
    newValue = newValue.slice(0, 1);
  }

  return { isCorrect: true, val: newValue };
};
