import React from "react";

const contentManagement = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7161 14.2236H5.49609"
        stroke="#808185"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7161 10.0371H5.49609"
        stroke="#808185"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25109 5.86035H5.49609"
        stroke="#808185"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9085 0.75C12.9085 0.75 5.23149 0.754 5.21949 0.754C2.45949 0.771 0.750488 2.587 0.750488 5.357V14.553C0.750488 17.337 2.47249 19.16 5.25649 19.16C5.25649 19.16 12.9325 19.157 12.9455 19.157C15.7055 19.14 17.4155 17.323 17.4155 14.553V5.357C17.4155 2.573 15.6925 0.75 12.9085 0.75Z"
        stroke="#808185"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default contentManagement;
