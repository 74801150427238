import React from "react";

const createAndManage = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5771 22.3686H7.75309C4.31209 22.3686 2.00009 19.9536 2.00009 16.3596V8.04562C2.00009 4.45162 4.31209 2.03662 7.75309 2.03662H11.4921C11.9061 2.03662 12.2421 2.37262 12.2421 2.78662C12.2421 3.20062 11.9061 3.53662 11.4921 3.53662H7.75309C5.16909 3.53662 3.50009 5.30662 3.50009 8.04562V16.3596C3.50009 19.0986 5.16909 20.8686 7.75309 20.8686H16.5771C19.1611 20.8686 20.8311 19.0986 20.8311 16.3596V12.3316C20.8311 11.9176 21.1671 11.5816 21.5811 11.5816C21.9951 11.5816 22.3311 11.9176 22.3311 12.3316V16.3596C22.3311 19.9536 20.0181 22.3686 16.5771 22.3686Z"
        fill="#808191"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.86768 15.4282H11.8447C12.2247 15.4282 12.5807 15.2812 12.8497 15.0122L20.3587 7.50324C20.6667 7.19524 20.8367 6.78524 20.8367 6.34924C20.8367 5.91224 20.6667 5.50124 20.3587 5.19324L19.1417 3.97624C18.5047 3.34124 17.4687 3.34124 16.8307 3.97624L9.35768 11.4492C9.09868 11.7082 8.95168 12.0522 8.94268 12.4172L8.86768 15.4282ZM11.8447 16.9282H8.09868C7.89668 16.9282 7.70268 16.8462 7.56168 16.7012C7.42068 16.5572 7.34368 16.3622 7.34868 16.1592L7.44268 12.3802C7.46168 11.6282 7.76468 10.9212 8.29668 10.3882H8.29768L15.7707 2.91524C16.9927 1.69524 18.9797 1.69524 20.2017 2.91524L21.4187 4.13224C22.0117 4.72424 22.3377 5.51124 22.3367 6.34924C22.3367 7.18724 22.0107 7.97324 21.4187 8.56424L13.9097 16.0732C13.3587 16.6242 12.6247 16.9282 11.8447 16.9282Z"
        fill="#808191"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.731 9.9166C19.539 9.9166 19.347 9.8436 19.201 9.6966L14.635 5.1306C14.342 4.8376 14.342 4.3626 14.635 4.0696C14.928 3.7766 15.402 3.7766 15.695 4.0696L20.261 8.6366C20.554 8.9296 20.554 9.4036 20.261 9.6966C20.115 9.8436 19.923 9.9166 19.731 9.9166Z"
        fill="#808191"
      />
    </svg>
  );
};

export default createAndManage;
