import React from "react";
import style from "./styles.module.scss";
import { TableItem } from "../../../components";

import Loader from "../../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

import { usePagination } from "../../../hooks/usePagination";

const TableBody = ({
  tableColumn,
  isUserTable,
  data = [],
  removeItem,
  loading,
  isCategoryTable,
  updateTable,
  setPage,
  hasMore,
}) => {
  return (
    <div className={style.tableBody}>
      <div className={style.tableBody__header}>
        {tableColumn.map((item) => {
          if (item.type === "cover") {
            return (
              <div
                key={item.type}
                className={style.tableBody__headerItem}
                style={{ width: item.width }}
              ></div>
            );
          } else {
            return (
              <div
                key={item.type}
                className={style.tableBody__headerItem}
                style={{ width: item.width }}
              >
                {item.title}
              </div>
            );
          }
        })}
      </div>

      <div className={style.tableBody__line} />

      {!loading && data.length === 0 && (
        <div className={style.tableBody__noResults}>NO RESULTS</div>
      )}

      {loading ? (
        <div className={style.tableBody__loader}>
          <Loader />
        </div>
      ) : (
        <div className={style.tableBody__items} id="id">
          <InfiniteScroll
            scrollableTarget="id"
            dataLength={data.length}
            next={() => {
              setPage((prev) => prev + 1);
            }}
            hasMore={hasMore}
            loading={<h4>Loading...</h4>}
            initialScrollY={0}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {data.map((item) => {
              return (
                <TableItem
                  updateTable={updateTable}
                  isCategoryTable={isCategoryTable}
                  removeItem={removeItem}
                  key={item._id}
                  isUserTable={isUserTable}
                  item={item}
                  tableColumn={tableColumn}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default TableBody;
