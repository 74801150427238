import React, { useState } from "react";
import style from "./styles.module.scss";
import clsx from "clsx";

const Input = ({
  label,
  placeholder,
  onChange,
  error,
  value,
  formik,
  name,
}) => {
  const [isFocused, setFocused] = useState(false);
  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };

  return (
    <div className={style.inputBlock}>
      <div
        className={clsx(style.inputBlock__label, { [style.focus]: isFocused })}
      >
        {label}
      </div>
      <input
        value={formik ? formik.values[name] : value}
        placeholder={placeholder ? placeholder : ""}
        className={clsx(style.input, { [style.error]: !!error })}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => onChange && onChange(e.target.value, name)}
      />
      {!!error && <div className={style.inputBlock__error}>{error}</div>}
    </div>
  );
};

export default Input;
