import React, { useState } from "react";
import style from "./styles.module.scss";
import TableBody from "../../blocks/common/tableBody";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../redux/actions/users";
import { Button } from "../../components";
import { fetchRequest } from "../../API/fetchRequest";
import { API } from "../../constants/api";
import { getToken } from "../../utils/tokenLS";

import { usePagination } from "../../hooks/usePagination";

const tableColumn = [
  { title: "Username", type: "userName", width: "30%" },
  { title: "Phone/Email", type: "email", width: "58%" },
  { title: "Registration date", type: "date", width: "12%" },
];

const UsersManagment = () => {
  const [users, setUsers] = useState([]);

  const { setPage, hasMore, loading, page } = usePagination({
    setItems: (newUsers, page) =>
      setUsers((prev) => (page > 1 ? [...prev, ...newUsers] : newUsers)),
    url: `admin/users`,
    limit: 15,
  });

  console.log("users", users);

  const downloadUsers = async () => {
    window.open("http://52.52.221.65:8080/api/admin/excelUsers", "_blank");

    // let token = await getToken();
    // const response = await fetch(`${API}/admin/excelUsers`, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify(undefined),
    // });
    // console.log("RESP", response);
    // downloadFile(response);
    // const resp = await fetchRequest("GET", "admin/excelUsers", undefined, true);
    // console.log("downloadUsers ==> resp", resp);
    // // downloadFile(resp.file);
  };

  // const downloadFile = (file) => {
  //   let element = document.createElement("a");

  //   element.setAttribute(
  //     "href",
  //     `data:text/csv;base64,` + encodeURIComponent(file)
  //   );

  //   element.setAttribute("download", "Users table");
  //   element.style.display = "none";
  //   document.body.appendChild(element);
  //   element.click();
  //   document.body.removeChild(element);
  // };

  // const downloadFile = (file) => {
  //   const uri = URL.createObjectURL(new Blob([file]));
  //   const link = document.createElement("a");
  //   link.href = uri;
  //   link.download = uri.split("/").pop() + ".xlsx";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  return (
    <div className={style.usersManagment}>
      <div className={style.usersManagment__header}>
        <div className={style.usersManagment__headerTitle}>Users managment</div>
        <div className={style.usersManagment__headerButton}>
          <Button text="Download" onClick={downloadUsers} />
        </div>
      </div>
      <TableBody
        tableColumn={tableColumn}
        data={users}
        hasMore={hasMore}
        setPage={setPage}
        isUserTable={true}
        loading={loading && page === 1}
      />
    </div>
  );
};

export default UsersManagment;
