import {
  SET_ADMINS,
  SET_CURRENT_ADMIN,
  CLEAR_CURRENT_ADMIN,
  CLEAR_ADMINS,
} from "../actionTypes/admins";
import { fetchRequest } from "../../API/fetchRequest";
import { setSuperAdmin } from "../../utils/superAdminLS";

export const setCurrentAdmin = (payload) => ({
  type: SET_CURRENT_ADMIN,
  payload,
});

export const getCurrentAdmin = () => async (dispatch) => {
  const resp = await fetchRequest("GET", "admin/info", undefined, true);

  if (resp?.success) {
    setSuperAdmin(resp.admin.super);
    dispatch(setCurrentAdmin(resp.admin));
  }
};

export const clearCurrentAdmin = () => ({
  type: CLEAR_CURRENT_ADMIN,
});

export const setAdmins = (payload) => ({
  type: SET_ADMINS,
  payload,
});

export const getAdmins = () => async (dispatch) => {
  const resp = await fetchRequest("GET", "admin/all", undefined, true);

  if (resp.success) {
    dispatch(setAdmins(resp.admins));
  }
};

export const clearAdmins = () => ({
  type: CLEAR_ADMINS,
});
