import * as Yup from "yup";
export const CreateAdminShema = Yup.object().shape({
  email: Yup.string()
    .min(6, "The login must be at least 6 characters long")
    .required("Login is a required field"),
  password: Yup.string()
    .matches(
      /^(?=.*\d).{8,}$/,
      "Must contain 8 characters and at least one number"
    )
    .required("Password is a required field"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is a required field"),
});
