export const setSuperAdmin = (value) => {
  localStorage.setItem("isSuperAdmin", value);
};

export const getSuperAdmin = () => {
  return localStorage.getItem("isSuperAdmin") === "true";
};

export const removeSuperAdmin = () => {
  localStorage.removeItem("isSuperAdmin");
};
