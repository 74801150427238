import React from "react";

const userManagementActive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8868 12.1463C17.5188 12.1463 17.1978 11.8753 17.1448 11.5003C17.0878 11.0893 17.3728 10.7103 17.7838 10.6533C18.8238 10.5073 19.6088 9.60526 19.6108 8.55326C19.6108 7.51026 18.8628 6.62826 17.8318 6.45926C17.4238 6.39226 17.1468 6.00626 17.2138 5.59726C17.2818 5.18826 17.6648 4.91426 18.0758 4.97926C19.8338 5.26826 21.1108 6.77226 21.1108 8.55526C21.1068 10.3503 19.7658 11.8903 17.9908 12.1393C17.9558 12.1443 17.9208 12.1463 17.8868 12.1463"
        fill="white"
      />

      <g mask="url(#mask0_1003_2722)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.8614 18.531C20.5594 18.531 20.2744 18.347 20.1604 18.048C20.0124 17.661 20.2074 17.227 20.5944 17.08C21.2724 16.822 21.2724 16.537 21.2724 16.4C21.2724 15.962 20.7154 15.656 19.6174 15.492C19.2084 15.43 18.9254 15.049 18.9864 14.638C19.0474 14.228 19.4274 13.955 19.8404 14.008C22.2634 14.371 22.7724 15.509 22.7724 16.4C22.7724 16.944 22.5584 17.937 21.1284 18.482C21.0404 18.515 20.9504 18.531 20.8614 18.531"
          fill="white"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8867 15.9141C9.51073 15.9141 6.67773 16.2061 6.67773 17.5961C6.67773 18.9971 9.51073 19.2911 11.8867 19.2911C14.2627 19.2911 17.0947 19.0001 17.0947 17.6131C17.0947 16.2091 14.2627 15.9141 11.8867 15.9141M11.8867 20.7911C10.2277 20.7911 5.17773 20.7911 5.17773 17.5961C5.17773 14.4141 10.2277 14.4141 11.8867 14.4141C13.5457 14.4141 18.5947 14.4141 18.5947 17.6131C18.5947 20.7911 13.7217 20.7911 11.8867 20.7911"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8868 5.5C10.1948 5.5 8.81877 6.877 8.81877 8.569C8.81577 9.389 9.12977 10.156 9.70477 10.735C10.2798 11.314 11.0458 11.635 11.8618 11.638L11.8868 12.388V11.638C13.5788 11.638 14.9558 10.262 14.9558 8.569C14.9558 6.877 13.5788 5.5 11.8868 5.5M11.8868 13.138H11.8598C10.6398 13.134 9.49677 12.656 8.64077 11.792C7.78277 10.929 7.31377 9.783 7.31877 8.566C7.31877 6.05 9.36776 4 11.8868 4C14.4068 4 16.4558 6.05 16.4558 8.569C16.4558 11.088 14.4068 13.138 11.8868 13.138"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.88572 12.1465C5.85172 12.1465 5.81672 12.1445 5.78172 12.1395C4.00672 11.8905 2.66672 10.3505 2.66272 8.55745C2.66272 6.77245 3.93972 5.26845 5.69772 4.97945C6.11872 4.91345 6.49172 5.19045 6.55972 5.59745C6.62672 6.00645 6.34972 6.39245 5.94172 6.45945C4.91072 6.62845 4.16272 7.51045 4.16272 8.55545C4.16472 9.60545 4.94972 10.5085 5.98872 10.6535C6.39972 10.7105 6.68472 11.0895 6.62772 11.5005C6.57472 11.8755 6.25372 12.1465 5.88572 12.1465"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.91212 18.5313C2.82312 18.5313 2.73312 18.5153 2.64512 18.4823C1.21412 17.9363 1.00012 16.9433 1.00012 16.4003C1.00012 15.5103 1.50912 14.3713 3.93312 14.0083C4.34612 13.9563 4.72412 14.2283 4.78612 14.6383C4.84712 15.0493 4.56412 15.4303 4.15512 15.4923C3.05712 15.6563 2.50012 15.9623 2.50012 16.4003C2.50012 16.5373 2.50012 16.8213 3.17912 17.0803C3.56612 17.2273 3.76112 17.6613 3.61312 18.0483C3.49912 18.3473 3.21412 18.5313 2.91212 18.5313"
        fill="white"
      />
    </svg>
  );
};

export default userManagementActive;
