import React from "react";
import style from "./styles.module.scss";
const Loader = () => {
  return (
    <div className={style.lds__roller}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
