import React from "react";

const close = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.81804 4.00166C10.1563 2.72 11.9713 2 13.8639 2C15.7565 2 17.5716 2.72003 18.9099 4.0017C20.2482 5.28336 21 7.02167 21 8.83421C21 10.6467 20.2483 12.385 18.91 13.6667L11.7015 20.5703C10.7455 21.4858 9.44904 22 8.09721 22C6.74535 22 5.44885 21.4857 4.49294 20.5702C3.53703 19.6547 3 18.4131 3 17.1184C3 15.8237 3.537 14.5822 4.49291 13.6667L11.7015 6.7631C12.2751 6.21377 13.0529 5.90532 13.8639 5.90532C14.6751 5.90532 15.453 6.21391 16.0265 6.76319C16.6 7.31248 16.9223 8.05747 16.9223 8.83427C16.9223 9.61101 16.6002 10.3559 16.0266 10.9053L8.81804 17.8089L7.37633 16.4281L14.5849 9.52454C14.776 9.34149 14.8834 9.09327 14.8834 8.83427C14.8834 8.57533 14.776 8.327 14.5848 8.14391C14.3936 7.96082 14.1343 7.85795 13.8639 7.85795C13.5935 7.85795 13.3343 7.96078 13.1432 8.14382L5.93462 15.0474C5.36107 15.5967 5.03889 16.3416 5.03889 17.1184C5.03889 17.8952 5.3611 18.6402 5.93465 19.1895C6.5082 19.7388 7.2861 20.0474 8.09721 20.0474C8.90838 20.0474 9.68623 19.7388 10.2598 19.1896L17.4683 12.286C18.4242 11.3705 18.9611 10.1289 18.9611 8.83421C18.9611 7.53954 18.4241 6.29789 17.4682 5.38241C16.5123 4.46694 15.2158 3.95263 13.8639 3.95263C12.512 3.95263 11.2157 4.4669 10.2597 5.38238L4.49291 10.9053L3.0512 9.52454L8.81804 4.00166Z"
        fill="#2B2D33"
        fill-opacity="0.24"
      />
    </svg>
  );
};

export default close;
