import { SET_USERS, CLEAR_USERS } from "../actionTypes/users";
import { fetchRequest } from "../../API/fetchRequest";

export const setUsers = (payload) => ({
  type: SET_USERS,
  payload,
});

export const getUsers = () => async (dispatch) => {
  const resp = await fetchRequest("GET", "admin/users", undefined, true);
  if (resp.success) {
    dispatch(setUsers(resp.users));
  }
};

export const clearUsers = () => ({
  type: CLEAR_USERS,
});
