import userAva from "./userAva.png";
import defaultItemIcon from "./defaultItemIcon.png";
import modalLogo from "./modalLogo.png";
import logo from "./logo.png";
import horisontalLogo from "./horisontalLogo.png";

export const icons = {
  userAva,
  defaultItemIcon,
  modalLogo,
  logo,
  horisontalLogo,
};
