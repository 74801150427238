import React, { useState } from "react";
import styles from "./styles.module.scss";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { fetchRequest } from "../../API/fetchRequest";
import { useHistory } from "react-router-dom";
import { setToken } from "../../utils/tokenLS";
import { setSuperAdmin } from "../../utils/superAdminLS";
import { icons } from "../../assets/png";
const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPassworError] = useState(null);

  const history = useHistory();

  const signIn = async () => {
    const resp = await fetchRequest("POST", "admin/login", {
      login: email,
      password,
    });
    if (resp.success) {
      setToken(resp.token);
      setSuperAdmin(resp.admin.super);
      history.push("/");
    } else {
      resp.field === "email"
        ? setEmailError(resp.message)
        : setPassworError(resp.message);
    }
  };

  const onChangeEmail = (val) => {
    !!emailError && setEmailError(null);
    setEmail(val);
  };

  const onChangePassword = (val) => {
    !!passwordError && setPassworError(null);
    setPassword(val);
  };

  return (
    <div className={styles.signIn}>
      <div className={styles.signIn__modal}>
        {/* <div className={styles.signIn__modalLogo} />
        <div className={styles.signIn__modalTitle}>Login to your account</div> */}
        <img src={icons.logo} className={styles.signIn__modalLogo} />

        <div className={styles.signIn__modalInput}>
          <Input
            label="Login"
            placeholder="Example@gmail.com"
            value={email}
            onChange={onChangeEmail}
            error={!!emailError && emailError}
          />
        </div>

        <Input
          label="Password"
          placeholder="*********"
          value={password}
          onChange={onChangePassword}
          error={!!passwordError && passwordError}
        />

        <div className={styles.signIn__modalButton}>
          <Button text="LOGIN" onClick={signIn} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
