import React, { useEffect, useState } from "react";
import style from "./styles.module.scss";
import CentralBlock from "../../blocks/createAndManage/centralBlock";
import RightBlock from "../../blocks/createAndManage/rightBlock";

import { usePagination } from "../../hooks/usePagination";
const CreateAndManage = () => {
  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState(true);
  const contentTypeNames = ["Categories", "Authors"];

  const [contentType, setContentType] = useState(contentTypeNames[0]);

  const { setPage, hasMore, loading, page } = usePagination({
    setItems: (newPosts, page) =>
      setData((prev) => (page > 1 ? [...prev, ...newPosts] : newPosts)),
    url:
      contentType === contentTypeNames[1]
        ? `admin/authors`
        : "admin/categories",
    limit: 15,
    renderData,
  });

  console.log("Data", data);

  useEffect(() => {
    setRenderData(!renderData);
    setPage(1);
  }, [contentType]);

  return (
    <div className={style.createBlock}>
      <div className={style.createBlock__centralBlock}>
        <CentralBlock
          data={data}
          loading={loading && page === 1}
          updateData={() => {
            setRenderData(!renderData);
            setPage(1);
          }}
          setPage={setPage}
          hasMore={hasMore}
          contentTypeNames={contentTypeNames}
          contentType={contentType}
          setContentType={setContentType}
          isAuthor={contentType === contentTypeNames[1]}
        />
      </div>

      <RightBlock
        updateData={() => {
          setRenderData(!renderData);
          setPage(1);
        }}
      />
    </div>
  );
};

export default CreateAndManage;
