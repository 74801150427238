import React from "react";

const contentManagement = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.90527 2.25977H3.41699C3.96191 2.25977 4.41895 1.81152 4.41895 1.25781C4.41895 0.704102 3.96191 0.255859 3.41699 0.255859H1.90527C1.36035 0.255859 0.912109 0.704102 0.912109 1.25781C0.912109 1.81152 1.36035 2.25977 1.90527 2.25977ZM7.13477 2.10156H18.2266C18.7012 2.10156 19.0791 1.73242 19.0791 1.25781C19.0791 0.783203 18.71 0.414062 18.2266 0.414062H7.13477C6.66016 0.414062 6.29102 0.783203 6.29102 1.25781C6.29102 1.73242 6.66016 2.10156 7.13477 2.10156ZM1.90527 7.66504H3.41699C3.96191 7.66504 4.41895 7.2168 4.41895 6.66309C4.41895 6.10938 3.96191 5.66113 3.41699 5.66113H1.90527C1.36035 5.66113 0.912109 6.10938 0.912109 6.66309C0.912109 7.2168 1.36035 7.66504 1.90527 7.66504ZM7.13477 7.50684H18.2266C18.7012 7.50684 19.0791 7.1377 19.0791 6.66309C19.0791 6.18848 18.71 5.81934 18.2266 5.81934H7.13477C6.66016 5.81934 6.29102 6.18848 6.29102 6.66309C6.29102 7.1377 6.66016 7.50684 7.13477 7.50684ZM1.90527 13.0703H3.41699C3.96191 13.0703 4.41895 12.6221 4.41895 12.0684C4.41895 11.5146 3.96191 11.0664 3.41699 11.0664H1.90527C1.36035 11.0664 0.912109 11.5146 0.912109 12.0684C0.912109 12.6221 1.36035 13.0703 1.90527 13.0703ZM7.13477 12.9121H18.2266C18.7012 12.9121 19.0791 12.543 19.0791 12.0684C19.0791 11.5938 18.71 11.2246 18.2266 11.2246H7.13477C6.66016 11.2246 6.29102 11.5938 6.29102 12.0684C6.29102 12.543 6.66016 12.9121 7.13477 12.9121Z"
        fill="#808185"
      />
    </svg>
  );
};

export default contentManagement;
