import { SET_USERS, CLEAR_USERS } from "../actionTypes/users";

const defaultState = {
  users: [],
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USERS: {
      return {
        ...state,
        users: [...action.payload],
      };
    }
    case CLEAR_USERS: {
      return { ...state, users: [] };
    }

    default:
      return state;
  }
};
export default user;
