export const pages = ["Home", "Meditate", "Sleep"];
export const postTypes = ["Music", "Story"];
export const daysData = [
  { name: "All time", value: null },
  { name: "Today", value: 1 },
  { name: "Last 7 days", value: 7 },
  { name: "Last 30 days", value: 30 },
];

export const totalCategories = ["Talks", "Guided Meditations"];
