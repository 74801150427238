import React, { useEffect, useState } from "react";
import style from "./styles.module.scss";
import TableBody from "../../blocks/common/tableBody";
import { fetchRequest } from "../../API/fetchRequest";
import { Modal, Input } from "../../components";

import { usePagination } from "../../hooks/usePagination";

const tableColumn = [
  { title: "Post date", type: "date", width: "12.5%" },
  { title: "Cover", type: "image", width: "10.9%" },
  { title: "Post title", type: "name", width: "34.3%" },
  { title: "Category", type: "categoryName", width: "30.4%" },
  { title: " ", type: "editOrDelete", width: "11.7%" },
];

const PostManagement = () => {
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [renderData, setRenderData] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const { setPage, hasMore, loading, page } = usePagination({
    setItems: (newPosts, page) =>
      setPosts((prev) => (page > 1 ? [...prev, ...newPosts] : newPosts)),
    url: `admin/posts`,
    limit: 15,
    renderData,
    fetchData: { text: searchQuery },
  });

  const onChangeSearchValue = (value) => {
    setSearchQuery(value);
    setPage(1);
    setRenderData(!renderData);
  };

  console.log("PostManagement --> posts", posts);

  const removePost = async () => {
    const resp = await fetchRequest(
      "delete",
      "admin/post",
      { ID: selectedPost },
      true
    );
    setSelectedPost(null);
    setShowModal(false);
    if (resp.success) {
      setRenderData(!renderData);
      setPage(1);
    }
  };

  return (
    <div className={style.postManage}>
      <div className={style.postManage__header}>
        <div className={style.postManage__title}>Content management</div>
        <div className={style.postManage__input}>
          <Input
            placeholder={"Search"}
            value={searchQuery}
            onChange={onChangeSearchValue}
          />
        </div>
      </div>
      {/* 
      <Input
        label={formik.values.author ? "Author's name" : "Category name"}
        formik={formik}
        placeholder={"Text"}
        name="name"
        onChange={onChange}
        error={formik.touched.name && formik.errors.name}
      /> */}

      <TableBody
        tableColumn={tableColumn}
        data={posts}
        removeItem={(id) => {
          setSelectedPost(id);
          setShowModal(true);
        }}
        loading={loading && page === 1}
        updateTable={() => {
          setRenderData(!renderData);
          setPage(1);
        }}
        setPage={setPage}
        hasMore={hasMore}
      />
      {showModal && (
        <Modal
          title={"Delete post?"}
          subtitle={"Are you sure you want to delete this post?"}
          onClose={() => {
            setSelectedPost(null);
            setShowModal(false);
          }}
          onSubmit={removePost}
        />
      )}
    </div>
  );
};

export default PostManagement;
