import React, { useEffect } from "react";
import style from "./styles.module.scss";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCategories } from "../../../redux/actions/categories";
import {
  CreatePost,
  CreateAndManage,
  Settings,
  CreateAndManageActive,
  ContentManagement,
  ContentManagementActive,
  Categories,
  CategoriesActive,
  UserManagement,
  CreatePostActive,
  SettingsActive,
  UserManagementActive,
} from "../../../assets/svg";
import { icons } from "../../../assets/png";
import { getCurrentAdmin } from "../../../redux/actions/admins";
import { removeToken } from "../../../utils/tokenLS";
import { getSuperAdmin, removeSuperAdmin } from "../../../utils/superAdminLS";

const LeftPanel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isSuperAdmin = getSuperAdmin();

  const logOut = () => {
    removeToken();
    removeSuperAdmin();

    history.push("/");
  };

  useEffect(() => {
    dispatch(getCurrentAdmin());
    dispatch(getCategories());
    // eslint-disable-next-line
  }, []);

  return (
    <div className={style.leftPanel}>
      {/* <div className={style.leftPanel__title}>Wild Minds</div> */}
      <img
        src={icons.horisontalLogo}
        className={style.leftPanel__logo}
        alt="Logo"
      />
      <div className={style.leftPanel__subtitle}>Admin tools</div>
      <div className={style.leftPanel__menu}>
        <NavLink activeClassName={style.active} to="/createPost">
          <div className={style.disactiveIcon}>
            <CreateAndManage />
          </div>
          <div className={style.activeIcon}>
            <CreatePostActive />
          </div>
          Create a post
        </NavLink>
        <NavLink activeClassName={style.active} to="/postManagement">
          <div className={style.disactiveIcon}>
            <ContentManagement />
          </div>
          <div className={style.activeIcon}>
            <ContentManagementActive />
          </div>
          Content management
        </NavLink>
        <NavLink activeClassName={style.active} to="/createAndManage">
          <div className={style.disactiveIcon}>
            <Categories />
          </div>
          <div className={style.activeIcon}>
            <CategoriesActive />
          </div>
          Categories
        </NavLink>
        <NavLink activeClassName={style.active} to="/usersManagment">
          <div className={style.disactiveIcon}>
            <UserManagement />
          </div>
          <div className={style.activeIcon}>
            <UserManagementActive />
          </div>
          Users managment
        </NavLink>
        {isSuperAdmin && (
          <NavLink activeClassName={style.active} to="/settings">
            <div className={style.disactiveIcon}>
              <Settings />
            </div>
            <div className={style.activeIcon}>
              <SettingsActive />
            </div>
            Settings
          </NavLink>
        )}
      </div>
      <div className={style.leftPanel__logOut}>
        <div>
          <div className={style.leftPanel__logOutName}>
            {isSuperAdmin ? "Super Admin" : "Admin"}
          </div>
          <div className={style.leftPanel__logOutSubtitle} onClick={logOut}>
            Log out
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
