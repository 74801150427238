import { API } from "../constants/api";
import { getToken } from "../utils/tokenLS";

export const fetchRequest = async (method, query, body, withAuth = false) => {
  let token = "";

  try {
    if (withAuth) {
      token = getToken();
    }
    console.log("token", token);
    console.log("body", body);

    const response = await fetch(`${API}/${query}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return data;
    } else if (response.status === 400) {
      const data = await response.json();
      return data;
    } else if (response.status === 422) {
      const data = await response.json();
      return data;
    } else if (response.status === 404) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
    } else if (response.status === 403) {
      return false;
    } else if (response.status === 500) {
      alert("Sorry the server is not available right now please try later");
    }
  } catch (error) {
    console.log(error);
  }
};
