import { useEffect, useState } from "react";
import { fetchRequest } from "../API/fetchRequest";

export const usePagination = ({
  setItems,
  url,
  limit,
  renderData,
  fetchData = {},
}) => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const getItems = async () => {
    setLoading(true);
    const resp = await fetchRequest("POST", url, { page, ...fetchData }, true);

    console.log("resp", resp);
    if (resp?.success) {
      if (url === "admin/categories") {
        setHasMore(resp.categories.length === limit);
        setItems(resp.categories, page);
      } else if (url === "admin/posts") {
        setHasMore(resp.posts.length === limit);
        setItems(resp.posts, page);
      } else if (url === "admin/users") {
        setHasMore(resp.users.length === limit);
        setItems(resp.users, page);
      } else {
        setHasMore(resp.authors.length === limit);
        setItems(resp.authors, page);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [page, renderData]);

  return {
    page,
    setPage,
    hasMore,
    setHasMore,
    loading,
  };
};
