import React from "react";
import style from "./styles.module.scss";
import clsx from "clsx";

const Switch = ({ value, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={clsx(style.switch, { [style.active]: value })}
    >
      <div
        className={clsx(style.switch__circle, { [style.active]: value })}
      ></div>
    </div>
  );
};

export default Switch;
