import React, { useState } from "react";
import style from "./styles.module.scss";
import {
  DropZone,
  Input,
  TextArea,
  Button,
  Select,
  Modal,
  Checkbox,
  Switch,
} from "../../../components";
import { useFormik } from "formik";
import { CreateCategoryShema } from "../../../utils/validations/CreateCategoryShema";
import { fetchFormDataRequest } from "../../../API/fetchFormDataRequest";
import { totalCategories } from "../../../constants/selectArrays";
const RightBlock = ({ updateData }) => {
  const [showModal, setShowModal] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      cover: null,
      author: false,
      totalCategory: "",
    },
    validationSchema: CreateCategoryShema,
    onSubmit: async (values, { resetForm }) => {
      console.log("values.author", values.author);
      if (values.author) {
        createAuthor();
      } else createCategory();
    },
  });

  const onChange = (value, name) => {
    if (name === "author" && value) {
      formik.setFieldValue("totalCategory", "");
    }

    formik.setFieldValue(name, value);
  };

  const createAuthor = async () => {
    const values = formik.values;
    const formData = new FormData();
    Object.keys(values).forEach((item) => {
      if (item !== "totalCategory") {
        if (item === "cover") {
          formData.append("avatar", values[item]);
        } else {
          formData.append(item, values[item]);
        }
      }
    });

    const resp = await fetchFormDataRequest(
      "POST",
      "admin/author",
      formData,
      true
    );

    setShowModal(false);

    if (resp.success) {
      formik.resetForm();
      updateData();
    }
  };

  const createCategory = async () => {
    const values = formik.values;
    const formData = new FormData();
    Object.keys(values).forEach((item) => {
      if (item === "totalCategory") {
        formData.append("mainCateg", values[item]);
      } else formData.append(item, values[item]);
    });

    const resp = await fetchFormDataRequest(
      "POST",
      "admin/category",
      formData,
      true
    );

    setShowModal(false);

    if (resp.success) {
      formik.resetForm();
      updateData();
    }
  };

  const saveCover = (file) => {
    if (file[0].type === "image/png" || file[0].type === "image/jpeg") {
      formik.setFieldValue("cover", file[0]);
    }
  };

  return (
    <div className={style.rightBlock}>
      {/* {showModal && (
        <Modal
          text="Are you sure you want to save the Settings?"
          onClose={() => setShowModal(false)}
          onSubmit={onSubmit}
        />
      )} */}
      <div className={style.rightBlock__checkbox}>
        <span className={style.rightBlock__checkboxLabel}>Create author</span>
        <Switch
          value={formik.values.author}
          onClick={(val) => onChange(!formik.values.author, "author")}
        />
      </div>

      <div className={style.rightBlock__dropzone}>
        <DropZone
          label={formik.values.author ? "Author photo" : "Category cover"}
          setFile={saveCover}
          file={formik.values.cover}
          removeFile={() => formik.setFieldValue("cover", null)}
          error={formik.touched.cover && formik.errors.cover}
        />
      </div>
      <Input
        label={formik.values.author ? "Author's name" : "Category name"}
        formik={formik}
        placeholder={"Text"}
        name="name"
        onChange={onChange}
        error={formik.touched.name && formik.errors.name}
      />

      <div className={style.rightBlock__field}>
        <TextArea
          label={
            formik.values.author
              ? "Author's description"
              : "Category description"
          }
          formik={formik}
          placeholder={"Text"}
          name="description"
          onChange={onChange}
        />
      </div>
      {!formik.values.author && (
        <div className={style.rightBlock__field}>
          <Select
            label={"Total categories"}
            data={totalCategories}
            onChange={onChange}
            formik={formik}
            name={"totalCategory"}
            placeholder="Text"
            error={formik.touched.totalCategory && formik.errors.totalCategory}
          />
        </div>
      )}

      <div className={style.rightBlock__buttonWrapper}>
        <div className={style.rightBlock__button}>
          <Button text="Save and publish" onClick={() => formik.submitForm()} />
        </div>
      </div>
    </div>
  );
};

export default RightBlock;
