import React from "react";

const createPostActive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.577 22.3691H7.753C4.312 22.3691 2 19.9541 2 16.3601V8.04611C2 4.45211 4.312 2.03711 7.753 2.03711H11.492C11.906 2.03711 12.242 2.37311 12.242 2.78711C12.242 3.20111 11.906 3.53711 11.492 3.53711H7.753C5.169 3.53711 3.5 5.30711 3.5 8.04611V16.3601C3.5 19.0991 5.169 20.8691 7.753 20.8691H16.577C19.161 20.8691 20.831 19.0991 20.831 16.3601V12.3321C20.831 11.9181 21.167 11.5821 21.581 11.5821C21.995 11.5821 22.331 11.9181 22.331 12.3321V16.3601C22.331 19.9541 20.018 22.3691 16.577 22.3691"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.86737 15.4285H11.8444C12.2244 15.4285 12.5804 15.2815 12.8494 15.0125L20.3584 7.50349C20.6664 7.19549 20.8364 6.78549 20.8364 6.34949C20.8364 5.91249 20.6664 5.50149 20.3584 5.19349L19.1414 3.97649C18.5044 3.34149 17.4684 3.34149 16.8304 3.97649L9.35737 11.4495C9.09837 11.7085 8.95137 12.0525 8.94237 12.4175L8.86737 15.4285ZM11.8444 16.9285H8.09837C7.89637 16.9285 7.70237 16.8465 7.56137 16.7015C7.42037 16.5575 7.34337 16.3625 7.34837 16.1595L7.44237 12.3805C7.46137 11.6285 7.76437 10.9215 8.29637 10.3885H8.29737L15.7704 2.91549C16.9924 1.69549 18.9794 1.69549 20.2014 2.91549L21.4184 4.13249C22.0114 4.72449 22.3374 5.51149 22.3364 6.34949C22.3364 7.18749 22.0104 7.97349 21.4184 8.56449L13.9094 16.0735C13.3584 16.6245 12.6244 16.9285 11.8444 16.9285V16.9285Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7308 9.91685C19.5388 9.91685 19.3468 9.84385 19.2008 9.69685L14.6348 5.13085C14.3418 4.83785 14.3418 4.36285 14.6348 4.06985C14.9278 3.77685 15.4018 3.77685 15.6948 4.06985L20.2608 8.63685C20.5538 8.92985 20.5538 9.40385 20.2608 9.69685C20.1148 9.84385 19.9228 9.91685 19.7308 9.91685"
        fill="white"
      />
    </svg>
  );
};

export default createPostActive;
