import React, { useEffect, useState } from "react";
import style from "./styles.module.scss";
import { icons } from "../../assets/png";
import { createPortal } from "react-dom";
import { Loader } from "..";
const Modal = ({
  title,
  subtitle,
  onClose,
  onSubmit,
  showLoader = false,
  showSuccessButton = false,
}) => {
  const [showModal, setShowModal] = useState(!showLoader);
  useEffect(() => {
    document.getElementById("root").classList.add("blur");
    return () => document.getElementById("root").classList.remove("blur");
  }, []);
  return createPortal(
    <div className={style.modalBlock}>
      {showModal ? (
        <div className={style.modal}>
          <div className={style.modal__title}>{title}</div>
          <div className={style.modal__subtitle}>{subtitle}</div>
          <div className={style.modal__bottom}>
            {!showSuccessButton ? (
              <>
                <div className={style.modal__cancelBtn} onClick={onClose}>
                  Cancel
                </div>
                <div className={style.modal__deleteBtn} onClick={onSubmit}>
                  Delete
                </div>
              </>
            ) : (
              <div className={style.modal__deleteBtn} onClick={onSubmit}>
                OK
              </div>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>,

    document.body
  );
};

export default Modal;
