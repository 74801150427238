import React from "react";

const box = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 1.75V3H21.25C21.6642 3 22 3.33579 22 3.75C22 4.16421 21.6642 4.5 21.25 4.5H2.75C2.33579 4.5 2 4.16421 2 3.75C2 3.33579 2.33579 3 2.75 3H8V1.75C8 0.783502 8.7835 0 9.75 0H14.25C15.2165 0 16 0.783502 16 1.75ZM9.5 1.75C9.5 1.61193 9.61193 1.5 9.75 1.5H14.25C14.3881 1.5 14.5 1.61193 14.5 1.75V3H9.5V1.75Z"
        fill="white"
      />
      <path
        d="M4.99657 6.17775C4.95667 5.76546 4.5901 5.46358 4.17781 5.50348C3.76552 5.54338 3.46364 5.90995 3.50354 6.32224L4.91609 20.9186C5.0029 21.8156 5.75674 22.5 6.65795 22.5H17.3422C18.2434 22.5 18.9972 21.8156 19.084 20.9186L20.4966 6.32224C20.5365 5.90995 20.2346 5.54338 19.8223 5.50348C19.41 5.46358 19.0434 5.76546 19.0035 6.17775L17.591 20.7741C17.5786 20.9022 17.4709 21 17.3422 21H6.65795C6.52921 21 6.42151 20.9022 6.40911 20.7741L4.99657 6.17775Z"
        fill="white"
      />
      <path
        d="M9.20598 7.50129C9.61948 7.47696 9.97441 7.79245 9.99873 8.20595L10.4987 16.706C10.5231 17.1194 10.2076 17.4744 9.79406 17.4987C9.38057 17.523 9.02564 17.2075 9.00132 16.794L8.50132 8.29403C8.47699 7.88054 8.79248 7.52561 9.20598 7.50129Z"
        fill="white"
      />
      <path
        d="M15.4987 8.29403C15.5231 7.88054 15.2076 7.52561 14.7941 7.50129C14.3806 7.47696 14.0256 7.79245 14.0013 8.20595L13.5013 16.706C13.477 17.1194 13.7925 17.4744 14.206 17.4987C14.6195 17.523 14.9744 17.2075 14.9987 16.794L15.4987 8.29403Z"
        fill="white"
      />
    </svg>
  );
};

export default box;
