import * as Yup from "yup";
export const CreatePostShema = Yup.object().shape({
  category: Yup.string().required("Category is required"),
  cover: Yup.mixed().nullable("Error").required("Cover is required"),
  name: Yup.string()
    .max(32, "Title must be at most 32 characters")
    .required("Title is required"),
  audio: Yup.mixed().nullable("Error").required("Audio is required"),
  author: Yup.string().required("Author is required"),
});
