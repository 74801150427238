import React, { useState } from "react";
import style from "./styles.module.scss";
import clsx from "clsx";

const TextArea = ({ label, placeholder, error, formik, name, onChange }) => {
  const [isFocused, setFocused] = useState(false);
  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };
  return (
    <div className={style.areaBlock}>
      <div
        className={clsx(style.areaBlock__label, {
          [style.focus]: isFocused,
        })}
      >
        {label}
      </div>
      <textarea
        value={formik ? formik.values[name] : ""}
        placeholder={placeholder ? placeholder : ""}
        className={clsx(style.textarea, { [style.error]: !!error })}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => {
          onChange && onChange(e.target.value, name);
        }}
      />
      {!!error && <div className={style.areaBlock__error}>{error}</div>}
    </div>
  );
};

export default TextArea;
