import { SET_CATEGORIES, CLEAR_CATEGORIES } from "../actionTypes/categories";
import { fetchRequest } from "../../API/fetchRequest";

export const setCategories = (payload) => ({
  type: SET_CATEGORIES,
  payload,
});

export const getCategories = (data) => async (dispatch) => {
  const data = {
    page: "Main",
    title: "",
    default: true,
    days: null,
  };
  const resp = await fetchRequest(
    "POST",
    "admin/categories",
    { default: false },
    true
  );
  if (resp.success) {
    dispatch(setCategories(resp.default));
  }
};

export const clearCategories = () => ({
  type: CLEAR_CATEGORIES,
});
